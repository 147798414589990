<template>
  <div :style="{ height: height, width: width }" class="bg" ref="chart"></div>
</template>
<script>
import { debounce } from "lodash";
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        seriesData: [],
      },
    },
    titleName: {
      type: String,
      default: "占比",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      colors: [
        "#4f77e1",
        "#feb837",
        "#26fff7",
        "#00ff5a",
        "#c6ff00",
        "#ffea00",
        "#ffa200",
      ], //['#00ff5a', '#26fff7']
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    let _this = this;
    this.chart.on("click", function (params) {
      if (params.data.type == "supervise") {
        _this.$emit("getClickData", params.name);
      } else if (params.data.type == "place") {
        _this.$emit("getClickData", params.name);
      }
    });
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);

      var rich = {
        value: {
          fontSize: 16,
          // align: "left",
          // fontWeight: 200,
          fontFamily: "苹方-简",
        },
        name: {
          // align: "left",
          fontSize: 16,
          padding: [10, 0],
          color: "#656565",
          // fontWeight: 200,
          fontFamily: "苹方-简",
        },
        unit: {
          fontSize: 16,
          // align: "left",
          // fontWeight: 200,
          fontFamily: "苹方-简",
        },
      };
      this.chart.setOption({
        series: [
          {
            type: "pie",
            radius: ["32%", "40%"],
            hoverAnimation: false,
            minAngle: 30,
            avoidLabelOverlap: true,
            label: {
              normal: {
                formatter: "{c|blue}\n{d}%\n{b}",
                formatter: function (params) {
                  var percent = params.percent.toFixed(2);
                  return (
                    "{value|" +
                    params.value +
                    "}{unit|" +
                    params.data.unit +
                    "}\n{value|" +
                    percent +
                    "%}\n{name|" +
                    params.name +
                    "}"
                  );
                },
                rich: rich,
              },
            },
            labelLine: {
              normal: {
                length: 10,
                length2: 30,
                lineStyle: {
                  type: "dashed",
                },
              },
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  return _this.colors[params.dataIndex];
                },
              },
            },
            data: _this.chartData.seriesData,
          },
        ],
      });
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>
