<template>
  <div class="public">
    <div class="container HomePlace">
      <!-- 四色码 -->
      <Toptab :topdata="forCodeData" :data="dataregion" type="scroll2" />

      <!-- 商户区域分布情况 中国地图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>

      <!-- 风险处置新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置新增变化趋势 </span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal"></KcSelect>
        </div>
        <div class="charts">
          <!-- <BarChart :chartData="barChartData" :legendData="['存管', '保险']"></BarChart> -->
          <LineChart
              v-loading="barChart1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="barChartData"
              :legendData="lineLegendData"
              :units="unitData"
              :yAxis="['']"
              :yAxisIndex="['0']"
          ></LineChart>
        </div>
      </div>
      <!-- 风险处置累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置累计变化趋势 </span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal3"></KcSelect>
        </div>
        <div class="charts">
          <LineChart
              v-loading="lineChart2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="lineChartData"
              :legendData="lineLegendData"
              :units="unitData2"
              :yAxis="['']"
              :yAxisIndex="['0']"
          ></LineChart>
        </div>
      </div>
      <!-- 风险处置按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置区域分布数量统计</span>
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="options"
            right="0"
            top="-35"
            @popupVal="popupVal5"
          ></KcSelect>
        </div>
        <!-- <Tab :optionData="tabData" @popupswitch="popupVal5"></Tab> -->
        <div class="element-tab">
          <el-table
              :data="tableDataAll"
              v-loading="loading1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              :row-key="rowKey"
              lazy
              :load="loadNextNode1"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort"
              :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="regionName"
              label="地区"
              :width="0.18 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <template>
              <el-table-column prop="numMoney" label="警告" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum1 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="custodyMoney" label="罚款" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum2 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  label="没收违法所得"
                  :width="0.30 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum3 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  label="吊销/暂扣执照"
                  :width="0.34 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum4 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  label="责令停产停业"
                  :width="0.30 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum5 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="行政拘留" sortable :width="0.25 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum6 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="其他" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum7 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
            </template>

          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
            >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
      <!-- 预付码按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>风险处置领域/行业分布数量统计</span>
          </div>
          <KcSelect
              isBlue
              keyLabel="haripinType"
              :optionData="options"
              right="0"
              top="-35"
              @popupVal="popupVal6"
          ></KcSelect>
        </div>
        <!-- <Tab :optionData="tabData" @popupswitch="popupVal6"></Tab> -->
        <div class="element-tab">
          <el-table
              :data="tableDataAll2"
              v-loading="loading2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              row-key="industryId"
              lazy
              :load="loadNextNode2"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort2"
              :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
                prop="industryName"
                label="领域/行业"
                sortable
                :width="0.32 * $bodyWidth"
                fixed
            >
            </el-table-column>
            <template>
              <el-table-column prop="numMoney" label="警告" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum1 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="custodyMoney" label="罚款" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum2 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  label="没收违法所得"
                  :width="0.30 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum3 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  label="吊销/暂扣执照"
                  :width="0.34 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum4 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  label="责令停产停业"
                  :width="0.30 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum5 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="行政拘留" sortable :width="0.25 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum6 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
              <el-table-column prop="insuranceMoney" label="其他" sortable :width="0.21 * $bodyWidth">
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.disposeNum7 }}</span
                  ><i
                    :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
                </template>
              </el-table-column>
            </template>

          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
            >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import Pmap from "@/components/charts/map"; //地图
import Baidumap from "@/components/baidumap/index.vue";
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieChart from "@/components/charts/PiePlace"; //饼图
import BarChart from "@/components/charts/bar";
import Tab from "@/components/tab.vue";
import Toptab from "@/components/toptab.vue";
import { cardType } from "@/api/common";
import {
  resourcesStatistics,
  damagedAdd,
  damagedCumulative,
  damagedCityStatistics,
  damagedIndustryStatistics,
  regionalDistributionInfo,
} from "@/api/homeplace";

export default {
  name: "HomePlace",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      barChart1: false,
      lineChart2: false,
      dataregion: [],
      mapOptions: [],
      baidumapOptions: [],
      showmap: true,
      showbmap: true,
      radio1: "1",
      radio2: "1",
      radioData: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      tabPosition4: "",
      tabPosition5: "",
      isTowPie: false,
      tabData: [
        {
          name: "涉及商户",
          value: 1,
          haripinType: 1,
        },
        {
          name: "涉及人数",
          value: 2,
          haripinType: 2,
        },
        {
          name: "涉及笔数",
          value: 3,
          haripinType: 3,
        },
        {
          name: "涉及金额",
          value: 4,
          haripinType: 4,
        },
      ],
      pieChartData: {
        seriesData: [
          {
            name: "存管",
            value: "0",
          },
          {
            name: "保险",
            value: "0",
          },
        ],
      },
      unitData: ["次", "次", "次", "次", "次", "次", "次"],
      unitData2: ["次", "次", "次", "次", "次", "次", "次"],
      barChartDataAll: [],
      barChartData: {
        xAxisData: [],
        seriesData: [],
      },
      lineLegendData: ["警告","罚款","没收违法所得","责令停产停业","吊销/暂扣执照","行政拘留","其他"],
      lineChartDataAll: [],
      lineChartData: {
        xAxisData: [],
        seriesData: [],
      },
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      maps: new Map(),
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      maps2: new Map(),
      forCodeData: [],
      options: [],
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    radio1: function (val) {
      this.damagedAdd();
    },
    radio2: function (val) {
      this.damagedCumulative();
    },
    tabPosition4: function (val) {
      this.damagedCityStatistics();
    },
    tabPosition5: function (val) {
      this.damagedIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  methods: {
    popupVal(val, row) {
      this.radio1 = val;
    },
    popupVal3(val, row) {
      this.radio2 = val;
    },
    popupVal5(val) {
      this.tabPosition4 = val;
    },
    popupVal6(val) {
      this.tabPosition5 = val;
    },
    intData() {
      this.cardType();
      this.resourcesStatistics();
      this.damagedAdd();
      this.damagedCumulative();
      this.damagedCityStatistics();
      this.damagedIndustryStatistics();
      this.getMapOptionData();
    },
    cardType() {
      cardType().then((res) => {
        res.data.unshift({ name: "全部", haripinType: "" });
        this.options = res.data;
      });
    },
    getMapOptionData() {
      this.showbmap = true;
      this.showmap = true;
      regionalDistributionInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: 0,
              warning: item.disposeNum1,
              fine: item.disposeNum2,
              illegalincome: item.disposeNum3,
              production: item.disposeNum4,
              license: item.disposeNum5,
              detention: item.disposeNum6,
              other: item.disposeNum7,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    resourcesStatistics() {
      resourcesStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          let topdata = [
            {
              name: "警告",
              val: 0,
              com: "次",
            },
            {
              name: "罚款",
              val: 0,
              com: "次",
            },
            {
              name: "没收违法所得",
              val: 0,
              com: "次",
            },
            {
              name: "责令停产停业",
              val: 0,
              com: "次",
            },
            {
              name: "吊销/暂扣执照",
              val: 0,
              com: "次",
            },
            {
              name: "行政拘留",
              val: 0,
              com: "次",
            },
            {
              name: "其他",
              val: 0,
              com: "次",
            },
          ];

          let data = [
            {
              name: "较昨日",
              data: [],
            },
            {
              name: "较上周",
              data: [],
            },
            {
              name: "较上月",
              data: [],
            },
            {
              name: "较上年",
              data: [],
            },
          ];

          res.data.map((v, i) => {
            topdata[i].val = v.num;
            data[0].data.push(v.yestodayNum);
            data[1].data.push(v.weekNum);
            data[2].data.push(v.monthNum);
            data[3].data.push(v.yearNum);
          });
          this.dataregion = data;
          this.forCodeData = topdata;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    damagedAdd() {
      this.barChart1 = true;
      damagedAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {
        //console.log('风险等级新增变化趋势',res)
        // this.barChartDataAll = res.data;
        // this.changeBarChartData();
        this.barChart1 = false;
        this.barChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "警告",
              data: res.data.disposeNum1,
            },
            {
              name: "罚款",
              data: res.data.disposeNum2,
            },
            {
              name: "没收违法所得",
              data: res.data.disposeNum3,
            },
            {
              name: "吊销/暂扣执照",
              data: res.data.disposeNum4,
            },
            {
              name: "责令停产停业",
              data: res.data.disposeNum5,
            },
            {
              name: "行政拘留",
              data: res.data.disposeNum6,
            },
            {
              name: "其他",
              data: res.data.disposeNum7,
            },
          ],
        };
      });
    },
    damagedCumulative() {
      this.lineChart2 = true;
      damagedCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      }).then((res) => {
        this.lineChart2 = false;
        //console.log('风险等级累计变化趋势',res)
        this.lineChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "警告",
              data: res.data.disposeNum1,
            },
            {
              name: "罚款",
              data: res.data.disposeNum2,
            },
            {
              name: "没收违法所得",
              data: res.data.disposeNum3,
            },
            {
              name: "吊销/暂扣执照",
              data: res.data.disposeNum4,
            },
            {
              name: "责令停产停业",
              data: res.data.disposeNum5,
            },
            {
              name: "行政拘留",
              data: res.data.disposeNum6,
            },
            {
              name: "其他",
              data: res.data.disposeNum7,
            },
          ],
        };
      });
    },
    damagedCityStatistics() {
      let _this = this;
      this.loading1 = true;
      damagedCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data;
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    loadNextNode1(tree, treeNode, resolve) {
      let _this = this;
      const pid = tree.regionId;
      _this.maps.set(pid, { tree, treeNode, resolve });
      damagedCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        resolve(res.data || []);
      });
    },
    damagedIndustryStatistics() {
      let _this = this;
      this.loading2 = true;
      damagedIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        // console.log('预付码按领域/行业统计',res)
        res.data.forEach((v) => {
          v.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data;
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      let _this = this;
      const pid = tree.industryId;
      _this.maps2.set(pid, { tree, treeNode, resolve });
      damagedIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data || []);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    PieChart,
    BarChart,
    Tab,
    Toptab,
    Baidumap,
    Pmap,
  },
  mounted() {
    this.intData();
  },
};
</script>

<style lang="scss" scoped >
.HomePlace {
  // 四色码
  .idiot-one {
    display: flex;
    flex-wrap: wrap;
    height: 420px;
    margin-bottom: 30px;
    background: white;
    font-size: 26px;
    color: #232a24;
    .boxtop {
      width: 100%;
      height: 128px;
      border-bottom: 1px solid #ececec;
      display: flex;
      align-items: center;
      .boxtopchild {
        width: 25%;
        height: 100%;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 50%;
        }
        & > div:nth-child(2) {
          color: #1d67ff;
        }
      }
    }
    .boxval {
      width: 100%;
      .boxvalchild {
        width: 100%;
        height: 62px;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eeeeee;
        .boxvalchildname {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .t {
          color: #ffba00;
          font-weight: bold;
        }
        .b {
          color: #59d2f3;
          font-weight: bold;
        }
      }
    }

    .box {
      width: 24.7%;
      text-align: center;
      .box-header {
        color: oldlace;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        span:nth-child(1) {
          font-size: 18px;
        }
        span:nth-child(2) {
          font-size: 30px;
          margin: 0 10px;
        }
      }
      .box-one {
        display: flex;
        justify-content: center;
        font-size: 14px;
        line-height: 32px;
        span:nth-child(1) {
          color: #9fa1a7;
        }
        span:nth-child(2) {
          color: #eee;
          width: 60px;
          text-align: left;
        }
        span:nth-child(3) {
          color: #eee;
          width: 10px;
          font-size: 16px;
          .t {
            color: #ffba00;
            font-weight: bold;
          }
          .b {
            color: #59d2f3;
            font-weight: bold;
          }
        }
      }
    }
  }
  .pie-box {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .echarts-box {
      position: relative;
      width: 100%;
      .pie_button {
        color: #9fa1a7;
        font-size: 28px;
        position: absolute;
        top: 6px;
        right: 20px;
        font-size: 14px;
      }
    }
    .pie-charts {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .pie-back {
        width: 412px;
        height: 412px;
        top: 50%;
        left: 50%;
        margin-top: -206px;
        margin-left: -206px;
        position: absolute;
        background: url("../assets/imgs/pie-bg2.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .tab-select-btn {
    margin: 46px 0;
    // position: absolute;
    // top: 6px;
    // right: 0;
  }
}
</style>
