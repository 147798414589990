<template>
  <div :class="`taptab_box ${type}`">
    <div class="toptab_title" ref="scrollBox"  @scroll="getScroll">
      <div
        class="toptab_title_child"
        v-for="(item, ind) in topdata"
        :key="ind"
        :class="index == ind ? 'toptab_title_childgl2' : 'toptab_title_childgl1'"

        @click="tabswitchdata(item, ind)"
      >
        <div class="toptab_data_val">
          {{ item.val }}
          <div class="toptab_data_com">{{ item.com }}</div>
        </div>
        <div class="toptab_data_name">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="toptab_val_box"  v-if="topdata.length">
      <div class="toptab_val_child" v-for="(item, ind) in data" :key="ind">
        <div class="toptab_val_child_name">{{ item.name }}</div>
        <div class="toptab_val_child_val">
          {{ item.data[index] }}
          <div class="toptab_val_child_com">{{ com || topdata[0].com }}</div>
          <i
            :class="
              item.data[index] > 0
                ? 'el-icon-top t'
                : item.data[index] < 0
                ? 'el-icon-bottom b'
                : ''
            "
          ></i>
        </div>
      </div>
    </div>
    <div class="taptab_range" v-if="type && topdata.length">
      <div class="taptab_range_item" :style="{width:this.offsetWidth / this.scrollWidth * 100 + '%',left:this.scrollLeft / this.scrollWidth * 100 + '%'}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    data: {
      type: Array,
      default: [],
    },
    topdata: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: '',
    },
  },
  watch:{
    topdata: function(val,oldVal){
      if(val.length){
        this.getScroll()

      }
    },

  },
  data() {
    return {
      index: 0,
      com:'',
      moveX: 0,
      startX: 0,
      offsetWidth: 0,
      scrollLeft: 0,
      scrollWidth: 0,
    };
  },
  components: {},
  methods: {
    tabswitchdata(val, ind) {
      this.index = ind;
      this.com = val.com;
    },
    getScroll(){
      this.$nextTick(()=>{
        this.offsetWidth = this.$refs.scrollBox.offsetWidth;
        this.scrollWidth = this.$refs.scrollBox.scrollWidth;
        this.scrollLeft = this.$refs.scrollBox.scrollLeft;
      })


    },

  },
};
</script>
<style lang="scss" scoped>
.taptab_box {
  height: 515px;
  background: white;
  margin: 0 0 30px;

  &.scroll{
    height: 545px;
    .toptab_title::-webkit-scrollbar {
      display: none;
    }
    .toptab_title{
      .toptab_title_child{
        width: 128px;
        flex-shrink: 0;
      }
    }
  }


  &.scroll2{
    height: 545px;
    .toptab_title::-webkit-scrollbar {
      display: none;
    }
    .toptab_title{
      .toptab_title_child{
        width: 222px;
        flex-shrink: 0;
      }
    }
  }
  &.high{
    height: 515px;
    .toptab_title{
      padding-top: 0;
      height: 115px;
    }
  }

  .toptab_title {
    //width: 100%;
    height: 165px;
    display: flex;
    padding-top: 50px;
    margin: 0 30px;
    overflow-x: scroll;
    .toptab_title_child {
      position: relative;
      width: 33%;
      height: 95px;
      border-radius: 10px;
      margin-right: 10px;
      .toptab_data_val {
        width: 100%;
        height: 45px;
        display: flex;
        line-height: 55px;
        justify-content: center;
        font-size: 26px;
      }
      .toptab_data_com {
        line-height: 55px;
        justify-content: center;
        font-size: 14px;
      }
      .toptab_data_name {
        width: 100%;
        height: 50px;
        line-height: 40px;
        font-size: 26px;
        text-align: center;
      }
    }
    &>.toptab_title_child:first-child{
      margin-left: 0px;
    }
    &>.toptab_title_child:last-child{
      margin-right: 0px;
    }
    .toptab_title_childgl1 {
      background: #f5f7fa;
      color: #232a24;
    }
    .toptab_title_childgl2 {
      background: #4f77e1;
      color: white;
    }
    .toptab_title_childgl2::after {
      content: "";
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid #4f77e1;
      height: 0px;
      width: 0px;
      position: absolute;
      bottom: -14px;
      left: 50%;
      margin-left: -7.5px;
    }
  }
  .toptab_val_box {
    width: 88%;
    height: 266px;
    // background: red;
    display: flex;
    flex-wrap: wrap;
    margin: 60px 6% 0 6%;
    .toptab_val_child {
      width: 50%;
      height: 50%;
      color: #232a24;
      .toptab_val_child_name {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
      }

      .toptab_val_child_val {
        width: 100%;
        height: 68px;
        line-height: 68px;
        justify-content: center;
        font-size: 40px;
        display: flex;
        .toptab_val_child_com {
          font-size: 24px;
          line-height: 78px;
          margin-left: 5px;
        }
        .t {
          color: #dd3f58;
          font-weight: bold;
          font-size: 12px;
          margin: 30px 0 0 5px;
        }
        .b {
          color: #00b19a;
          font-weight: bold;
          font-size: 12px;
          margin: 30px 0 0 5px;
        }
      }
    }

    .toptab_val_child:nth-child(1) {
      border-bottom: 1px dashed #ececec;
      border-right: 1px dashed #ececec;
    }
    .toptab_val_child:nth-child(2) {
      border-bottom: 1px dashed #ececec;
    }
    .toptab_val_child:nth-last-child(2) {
      border-right: 1px dashed #ececec;
      .toptab_val_child_name {
        margin-top: 20px;
      }
    }
    .toptab_val_child:nth-last-child(1) {
      .toptab_val_child_name {
        margin-top: 20px;
      }
    }
  }
  .taptab_range{
    width: 150px;
    height: 8px;
    margin: 30px auto;
    position: relative;
    background: #ececec;
    border-radius: 8px;
    .taptab_range_item{
      position: absolute;
      left: 0;
      width: 0;
      height: 8px;
      background: #4f77e1;
      border-radius: 8px;
    }
  }
}
</style>
