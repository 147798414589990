<template>
  <div class="tabswitch" :style="{ bottom: bottom, left: left }">
    <div
      class="tabswitchchild"
      :class="index == ind ? 'tabswitchchildgaoliang' : 'tabswitchchildhigh'"
      v-for="(val, ind) in optionData"
      :key="ind"
      @click="tabswitchdata(val, ind)"
    >
      {{ val.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    optionData: {
      type: Array,
      default: [],
    },
    bottom: {
      type: String,
      default: "100%",
    },
    left: {
      type: String,
      default: "100%",
    },
    istrue: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  components: {},
  methods: {
    tabswitchdata(val, ind) {
      this.index = ind;
      console.log(val);
      this.$emit("popupswitch",val)
    },
  },
};
</script>
<style lang="scss" scoped>
.tabswitch {
  width: 95%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2.5% 30px 2.5%;
  .tabswitchchild {
    width: 333px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    border-radius: 10px;
    margin-right: 20px;
    text-align: center;
    font-size: 24px;
    position: relative;
  }
  .tabswitchchildhigh {
    background: #ececec;
    color: #232a24;
  }
  .tabswitchchildgaoliang {
    background: #4f77e1;
    color: white;
  }
  .tabswitchchildgaoliang:before {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #4f77e1;
    height: 0px;
    width: 0px;
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
  }

  .tabswitchchild:nth-last-child(1) {
    margin-right: 0px;
  }
}
</style>
