import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 涉及资源情况统计
 */
export const resourcesStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/damaged/resourcesStatistics',
        params
    })
}

/**
 * 风险等级新增趋势
 */
export const damagedAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/damaged/damagedAdd',
        params
    })
}

/**
 * 风险等级累计趋势
 */
export const damagedCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/damaged/damagedCumulative',
        params
    })
}

/**
 * 风险处置按城市/地区统计
 */
export const damagedCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/damaged/damagedCityStatistics',
        params
    })
}

/**
 * 风险处置按领域/行业统计
 */
export const damagedIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/damaged/damagedIndustryStatistics',
        params
    })
}

/**
 * 风险处置区域分布图
 */
export const regionalDistributionInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/damaged/regionalDistributionInfo',
        params
    })
}
